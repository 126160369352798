.pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.pagination__amount-menu {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.pagination__rowsPerPage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.pagination__icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.pagination__icons__icon {
    margin: 0 7px 0 7px;
}

.pagination__icons__icon:hover {
    transition: all .3s linear;
    border-radius: .125rem;
    background-color: #eee;
}

@media screen and (max-width: 576px) {
    .pagination {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pagination > * {
        margin: 5px 0 5px 0;
    }
}