.Product{
  background-color: blue;
}

.categoryProducts{
  display: none;
}

.categoryProducts.visible{
  display: block;
}

.simple-button{
  background-color: white;
  color: blue;
}

.customModalWrapper{
  display: block;
}


.fill {
  height: 100vh;
}

body {
  background-color: rgb(237, 236, 236);
}

.brand {}

.brand__table{}

.brand__table td {
  padding: 5px;
}

.brand__table td:nth-child(1) {
  direction: rtl;
  font-weight: bolder;
  border-right: 1px rgb(237, 236, 236) solid;
}

.brand > * {
  margin: 1% 0 1% 0;
}

.categories > * {
  margin: 1% 0 1% 0;
}

.mui-table-row:hover {
  cursor: pointer;
}

.point-system__card-1 {
  margin: 1% 0 1% 0;
}

.point-system__card-2 {
  margin: 1% 0 1% 0;
}

.combinations-of-products__table{}

.combinations-of-products__table td {
  padding: 5px;
}

.combinations-of-products__table td:nth-child(1) {
  direction: rtl;
  font-weight: bolder;
  border-right: 1px rgb(237, 236, 236) solid;
}
