.recharts-tooltip-wrapper .custom-tooltip {
    width: 200px;
    margin: 0;
    line-height: 24px;
    border: 1px solid #f5f5f5;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
}

.recharts-tooltip-wrapper .custom-tooltip .label {
    margin: 0;
    color: #666;
    font-weight: 700;
}
.recharts-tooltip-wrapper .custom-tooltip .desc {
    margin: 0;
    color: #999;
}
.recharts-tooltip-wrapper .custom-tooltip .intro {
    border-top: 1px solid #f5f5f5;
    margin: 0;

}

body {
    background-color: #fafafa;
}

.dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.dashboard > * {
    flex: 1;
    margin:5px;
}

.client-overview {
    display: grid;
    align-content: space-between;
    grid-template-columns: 60% auto;
    grid-gap: 10px 10px;
}

.client-overview__info {
    flex: 1;
}

.client-overview__rewards {
    display: grid;
    align-content: space-between;
    grid-template-columns: auto;
    grid-gap: 10px 10px;
    flex: 1;
}

@media screen and (max-width: 768px){
    .dashboard {
        flex-direction: column;
    }

    .client-overview {
        grid-template-columns: auto;
    }

    table th, table td {
        padding: 0 !important;
        word-wrap: break-word !important;

    }

    table{
        min-width: initial!important;
        word-break: break-word;

        display: block;
        width: 100%;
    }

    th, td {
        padding: 0!important;
        font-size: 10px!important;
    }

    td button {
        padding: 0 4px!important;
        font-size: 5px!important;
    }

    tr {
        height: 32px!important;
    }


    main{

        width: initial!important;
        padding: 16px!important;
    }
}